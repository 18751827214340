// import { Link } from "gatsby"
import React from "react"
import {Modal, Carousel, Row, Col, Container, Button} from 'react-bootstrap';


class HowCarousel extends React.Component {

  render() {
        return (

            <Carousel indicators={false} className='' interval={100000} className='green-b mt-0 px-5'>

                {/* ---------- 1 ----------  */}

                <Carousel.Item className=''>
                        <Row>
                            <Col xs={12} sm={2}>
                            <h3>
                            1.
                              </h3>

                            </Col>
                            <Col xs={12} sm={5} className='yellow'>
                              <p>
                              Choose a theme such as a trip - traveling by car, plane, bicycle, or walking. 
                              </p>
                            </Col>
                            <Col xs={12} sm={5} className='own-carousel'>
                              <p>
                              Or choose an everyday activity such as brushing teeth or bathtime. Have fun with fantasy and magic (wands, spells, giants, unicorns).
                              </p>
                            </Col>

                        </Row>
                </Carousel.Item>

                {/* ---------- 2 ----------  */}

                <Carousel.Item className=''>
                        <Row>
                        <Col xs={12} sm={2}>
                              <h3>
                                2.
                              </h3>
                            </Col>
                            <Col xs={12} sm={5} className='yellow'>
                              <p>
                              What are the main objects in your story?
                              </p>
                            </Col>
                            <Col xs={12} sm={5} className='own-carousel'>
                              <p>
                              Bathtime: water, bubbles, bath toys
                              </p>
                            </Col>
                        </Row>
                </Carousel.Item>

                {/* ---------- 3 ----------  */}

                <Carousel.Item className=''>
                        <Row>
                        <Col xs={12} sm={2}>
                              <h3>
                                3.
                              </h3>
                            </Col>
                            <Col xs={12} sm={5} className='yellow'>
                              <p>
                              Who is the main character in the story? 
                              </p>
                            </Col>
                            <Col xs={12} sm={5} className='own-carousel'>
                              <p>
                              Your child? A family pet? An imaginary friend?
                              </p>
                            </Col>
                        </Row>
                </Carousel.Item>

                {/* ---------- 4 ----------  */}

                <Carousel.Item className=''>
                        <Row>
                        <Col xs={12} sm={2}>
                              <h3>
                                4.
                              </h3>
                            </Col>
                            <Col xs={12} sm={5} className='yellow'>
                              <p>
                              What is going to be the ‘oh no’ moment or the turning point of the story? 
                              </p>
                            </Col>
                            <Col xs={12} sm={5} className='own-carousel'>
                              <p>
                              For example the sea runs dry (drain the bath) and the hero has to escape (lift child out of the bath and wrap in a towel).
                              </p>
                            </Col>
                        </Row>
                </Carousel.Item>

                {/* ---------- 5 ----------  */}

                <Carousel.Item className=''>
                        <Row>
                        <Col xs={12} sm={2}>
                              <h3>
                                5.
                              </h3>
                            </Col>
                            <Col xs={12} sm={5} className='yellow'>
                              <p>
                              Write out each sentence including the objects and actions to be used.
                              </p>
                            </Col>
                            <Col xs={12} sm={5} className='own-carousel'>
                              <p>
                              See following pages for story example.
                              </p>
                            </Col>
                        </Row>
                </Carousel.Item>







            </Carousel>

        );

    }




}


export default HowCarousel;