import React from "react";
import { Table} from "react-bootstrap";

import TableIcon1 from "../images/table-icons-1.svg";
import TableIcon2 from "../images/table-icons-2.svg";
import TableIcon3 from "../images/table-icons-3.svg";
import TableIcon4 from "../images/table-icons-4.svg";
import TableIcon5 from "../images/table-icons-5.svg";
import TableIcon6 from "../images/table-icons-6.svg";
import TableIcon7 from "../images/table-icons-7.svg";
import TableIcon8 from "../images/table-icons-8.svg";
import TableIcon9 from "../images/table-icons-9.svg";
import TableIcon10 from "../images/table-icons-10.svg";
import TableIcon11 from "../images/table-icons-11.svg";
import TableIcon12 from "../images/table-icons-12.svg";
import TableIcon13 from "../images/table-icons-13.svg";

const StoryTemplate = () => (

  <Table>
  <thead>
    
  <tr>
      <th></th>
      <th><TableIcon1 className='table-icon' style={{ width: '35%' }}/></th>
      <th><TableIcon2 className='table-icon' style={{ width: '35%' }}/></th>
      <th></th>
      <th><TableIcon4 className='table-icon' style={{ width: '35%' }}/></th>
    </tr>

    <tr className='line '>
      <th></th>
      <th > <h1>Story</h1> </th>
      <th><h1>Object/<br/>Gesture</h1> </th>
      <th></th>
      <th ><h1>Action</h1></th>
    </tr>

    
  </thead>
<br/>
  <tbody>
    <tr className='table-phone'> 
      <td >1</td>
      <td >Asha asks her mom: “Please Mom can we go to the park? Please?” </td>
      <td className='red'>Hands</td>
      <td ><TableIcon3 className='table-icon' style={{ width: '35%' }}/></td>
      <td className='neu-reg'>Sign for please with your hands.</td>
    </tr>

    <tr className='table-phone'> 
      <td >2</td>
      <td >Mom replies: “Ok, let's go.”
Asha lifts her arms in the air and with a smile says: “Yay!!”</td>
      <td className='red'>Hands and Facial Expression</td>
      <td ><TableIcon8 className='table-icon' style={{ width: '35%' }}/></td>
      <td className='neu-reg'>Show a smile on your face and lift arms up in the air.</td>
    </tr>

    <tr className='table-phone'> 
      <td >3</td>
      <td >At the park Asha runs through the green grass,</td>
      <td className='red'>Green fabric/ green pom pom or real grass</td>
      <td ><TableIcon7 className='table-icon' style={{ width: '35%' }}/></td>
      <td className='neu-reg'>Spread out the green fabric and run your hands through it. Or shake the green pom pom</td>
    </tr>

    <tr className='table-phone'> 
      <td >4</td>
      <td >smells flowers,</td>
      <td className='red'>Real flowers or plastic flowers</td>
      <td ><TableIcon6 className='table-icon' style={{ width: '35%' }}/></td>
      <td className='neu-reg'>Smell flowers</td>
    </tr>

    <tr className='table-phone'> 
      <td >5</td>
      <td >and goes down the slide: “1,2,3 wheeee”...bump!</td>
      <td className='red'>Gestures with hands and facial expression</td>
      <td ><TableIcon5 className='table-icon' style={{ width: '35%' }}/></td>
      <td className='neu-reg'>Pretend to climb up a slide and count 5 steps on your way up. Show a surprised face when you land at the end ‘bump.’</td>
    </tr>

    <tr className='table-phone'> 
      <td >6</td>
      <td >Then her mom pushes Asha on the swing, and Asha pretends to go as high as outer space.</td>
      <td className='red'>Gestures with hands</td>
      <td ><TableIcon11 className='table-icon' style={{ width: '35%' }}/></td>
      <td className='neu-reg'>Give a push, and then pretend to fly in slow motion.</td>
    </tr>

    <tr className='table-phone'> 
      <td >7</td>
      <td >She imagines she's an astronaut flying around the planets </td>
      <td className='red'>Bubbles</td>
      <td ><TableIcon10 className='table-icon' style={{ width: '35%' }}/></td>
      <td className='neu-reg'>Blow bubbles</td>
    </tr>
    
    <tr className='table-phone'> 
      <td >8</td>
      <td >and stars. </td>
      <td className='red'>Sing</td>
      <td ><TableIcon9 className='table-icon' style={{ width: '35%' }}/></td>
      <td className='neu-reg'>Sing ‘Twinkle Twinkle Little Star’</td>
    </tr>

    <tr className='table-phone'> 
      <td >9</td>
      <td >Suddenly it starts to rain,</td>
      <td className='red'>Spray water bottle</td>
      <td ><TableIcon13 className='table-icon' style={{ width: '35%' }}/></td>
      <td className='neu-reg'>Spray in the air and on hands to feel the rain.</td>
    </tr>

    <tr className='table-phone'> 
      <td >10</td>
      <td >Asha's mom says: “I think it's time to go home and eat some ice cream.”</td>
      <td className='red'>Ice cream</td>
      <td ><TableIcon12 className='table-icon' style={{ width: '35%' }}/></td>
      <td className='neu-reg'>Sign for home and prepare an ice cream cone or pretend to eat an ice cream cone.</td>
    </tr>


  </tbody>
  
</Table>


  );

export default StoryTemplate;

