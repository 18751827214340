import React from "react";
import {Container, Row, Col, Button, Table, Image} from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";

import OwnCarousel from "../components/OwnCarousel";
import StoryTemplate from "../components/StoryTemplate";

import {Link} from "gatsby";

import {Spring} from 'react-spring/renderprops'
import VisibilitySensor from "react-visibility-sensor";

import Globe from "../images/globe-animated.svg";
import Pencil from "../images/pencil.svg";
import Break from "../images/break.svg";
import Park from "../images/park.svg";



// ---------- Colors Variables ---------- //

const red = '#F0625D';

// ---------- Component ---------- //

class yourown extends React.Component {

    constructor(props) {
        super(props)
        this.handleScroll = value => this.parallax && this
            .parallax
            .scrollTo(value)
    }

    render() {

        return (

            <Layout>
                <SEO title="Write Your Own"/> 

{/* ---------------------- Page Contents Here --------------------- */}
<Container fluid className='red-b'>

{/* ------------------------ Section 1 ------------------------ */}

<Container>
<div className='sections-top-gap'></div>

    <Row>
        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
        <VisibilitySensor>
            {({ isVisible }) => (
                  <Spring delay={100} to={{ opacity: isVisible ? 1 : 0 }}>
                  {({ opacity  }) => 
                <div style={{ opacity }} className='how'>
                    <h4 className='yellow pt-5 pb-5'>
                        Write your own story inspired by everyday activities
                    </h4>
                    </div>
                    }
                    </Spring>
                    )}
        </VisibilitySensor>
        </Col>

        <Col className='text-center pt-5 pb-5'>
            <Globe  id='globe' style={{width: '80%'}}/>
        </Col>
    </Row>

    </Container>

{/* ------------------------ Green ROW ------------------------ */}

        <Row className='green-b'>

{/* ------------------------ Section 2 ------------------------ */}

            <Container className='pt-5 pb-5'>
                <Row>
                    
                    <Col xs={10}>
                        <h1>
                        Guidelines:
                        </h1>
                    </Col>

                    <Col xs={2}>
                        <Pencil className='float-right' style={{maxWidth: '50px'}}/>
                    </Col>

                    <Col xs={12} className='pt-5'>
                        <OwnCarousel/>
                    </Col>

                </Row>
            </Container>

{/* ------------------------ ! ------------------------ */}

        </Row>

        <Row className='skin-b'>

{/* ------------------------ Section 2 ------------------------ */}

            <Container className='pt-5 pb-4'>
                <Row>
                        <Col xs={12}>
                        <p className='green'>
                        Here is an example:
                        </p>
                        </Col>

                        <Col xs={12} sm={6}>
                        <h4 className='pb-4'>
                        Asha’s Adventures at The Park
                        </h4>
                        </Col>
                       <Col xs={12} sm={6} className='float-right text-center' >
                       <Park style={{width: '60%', height: 'auto'}}/>
                    </Col>
                </Row>
                <Row className='own'>
                    <Col xs={12} className=''>
                        <p className='pt-5'>
                        Asha asks her mom: “Please Mom can we go to the park? Please?”<br/><br/>
                        Mom replies: “Ok, let’s go!” <br/><br/>
                        Asha lifts her arms in the air and with a smile says: “Yay!”<br/><br/>
                        At the park, Asha runs through the green grass, smells the flowers, and goes down the slide: “1,2,3 wheeeeee” ...bump!<br/><br/>
                        Then her mom pushes Asha on the swing, and Asha pretends to go as high as outer space.<br/><br/>
                        She imagines she’s an astronaut flying around the planets and stars. <br/><br/>
                        Suddenly it starts to rain, and Asha’s mom says: “I think it’s time to go home…and eat some ice cream.”<br/><br/>
                        </p>
                        </Col>
                        <Col xs={12}>
                        <br/>
                        <Break style={{maxWidth: '120px', height: 'auto'}}/>
                        <br/><br/>
                        <p className='line pb-5'>
                        Let's break it down...
                        </p>
                    </Col>
                </Row>
            </Container>


{/* ------------------------ ! ------------------------ */}
        </Row>

{/* ------------------------ Green ROW ------------------------ */}

        <Row className='skin-b'>

{/* ------------------------ Section 2 ------------------------ */}

            <Container className='pt-0 pb-5' style={{overflowX:'auto'}}>
                <Row >
                    <Col >  
                        <StoryTemplate />

                    </Col>  
                </Row>
            </Container>



{/* ------------------------ ! ------------------------ */}
        </Row>

{/* ------------------------ ! ------------------------ */}


    </Container>


            </Layout>

        );
    }
}

export default yourown;
